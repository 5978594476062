import React, { useState, useEffect } from 'react';
import { getAuth } from "firebase/auth";
import axios from 'axios';
import './SubscriptionPage.css';

const SubscriptionPage = () => {
  const [user, setUser] = useState(null);
  const [selectedPlan, setSelectedPlan] = useState(null);
  const [timeLeft, setTimeLeft] = useState({ hours: 0, minutes: 0, seconds: 0 });
  const auth = getAuth();
  const razorpayKey = process.env.RAZORPAY_KEY_ID; 
  const endDate = new Date('2024-12-01T00:00:00Z');

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      setUser(user);
    });

    return () => unsubscribe();
  }, [auth]);

  useEffect(() => {
    const updateTimer = () => {
      const now = new Date();
      const difference = endDate - now;

      if (difference <= 0) {
        setTimeLeft({ hours: 0, minutes: 0, seconds: 0 });
      } else {
        const hours = Math.floor(difference / (1000 * 60 * 60));
        const minutes = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60));
        const seconds = Math.floor((difference % (1000 * 60)) / 1000);

        setTimeLeft({ hours, minutes, seconds });
      }
    };

    updateTimer();
    const timer = setInterval(updateTimer, 1000);

    return () => clearInterval(timer);
  }, []);

  const formatTime = ({ hours, minutes, seconds }) => {
    return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
  };

  const handlePlanSelect = async (plan) => {
    setSelectedPlan(plan);
  
    try {
      const response = await axios.post('/.netlify/functions/api/create-order', {
        amount: plan === '3months' ? 999 : 4999,
        currency: 'INR',
        receipt: `receipt_${Date.now()}`,
        notes: {
          email: user.email,
          plan: plan
        }
      });
  
      const options = {
        key: razorpayKey, 
        amount: response.data.amount,
        currency: response.data.currency,
        name: 'Olivia AI Relationship Advisor',
        description: `${plan === '3months' ? '3 Months' : 'Lifetime'} Subscription`,
        order_id: response.data.id,
        handler: function (response) {
          axios.post('/.netlify/functions/api/verify-payment', {
            razorpay_payment_id: response.razorpay_payment_id,
            razorpay_order_id: response.razorpay_order_id,
            razorpay_signature: response.razorpay_signature,
            user: user,
            plan: plan
          }).then((verificationResponse) => {
            if (verificationResponse.data.success) {
              window.location.href = '/chat';
            } else {
              alert('There was an error verifying your payment. Please contact support.');
            }
          }).catch((error) => {
            console.error('Error verifying payment:', error);
            alert('There was an error verifying your payment. Please contact support.');
          });
        },
        prefill: {
          email: user.email,
          contact: user.phoneNumber
        },
        theme: {
          color: '#3399cc'
        }
      };
  
      const rzp = new window.Razorpay(options);
      rzp.open();
    } catch (error) {
      console.error('Error creating order:', error);
      alert('There was an error processing your request. Please try again.');
    }
  };

  if (!user) {
    return <div>Please sign in to access this page.</div>;
  }

  return (
    <div className="subscription-page">
      <h1>Choose Your Subscription Plan</h1>
      <div className="plan-options">
        <div 
          className={`plan ${selectedPlan === '3months' ? 'selected' : ''}`} 
          onClick={() => handlePlanSelect('3months')}
        >
          <h2>3 Months Plan</h2>
          <p className="price">
            <span className="original-price">₹3999</span>
            <span className="discounted-price">₹999</span>
            <span>/3 months</span>
          </p>
          <p className="offer-ends">Offer ends in: {formatTime(timeLeft)}</p>
          <ul>
            <li>Personalized advice</li>
            <li>100 messages daily</li>
            <li>No generic BS</li>
            <li>24/7 chat support</li>
          </ul>
          <button className="get-started-button">Get Started</button>
        </div>
        <div 
          className={`plan ${selectedPlan === 'lifetime' ? 'selected' : ''} popular`} 
          onClick={() => handlePlanSelect('lifetime')}
        >
          <div className="popular-badge">Most Popular</div>
          <h2>Lifetime Plan</h2>
          <p className="price">
            <span className="original-price">₹11000</span>
            <span className="discounted-price">₹4999</span>
            <span>/lifetime</span>
          </p>
          <p className="offer-ends">Offer ends in: {formatTime(timeLeft)}</p>
          <ul>
            <li>Personalized advice</li>
            <li>Unlimited messages</li>
            <li>No generic BS</li>
            <li>Priority support</li>
            <li>Future updates included</li>
          </ul>
          <button className="get-started-button">Get Started</button>
        </div>
      </div>
    </div>
  );
};

export default SubscriptionPage;

